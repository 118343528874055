import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title="Contact" description={data.site.siteMetadata.description}></SEO>
    <h1>Contact</h1>
    <h2>Email</h2>
    <p>
      The best way to reach out to me is to send me an email:{" "}
      <a href={"mailto:" + data.site.siteMetadata.email}>
        {data.site.siteMetadata.email}
      </a>
    </p>
    <p>
      If you want to encrypt your email you can let your email client
      automatically fetch my{" "}
      <a href="/simon@esslinger.dev.asc">OpenPGP Public Key</a> using{" "}
      <a href="https://wiki.gnupg.org/WKD">Web Key Directory</a>.
    </p>
    <h2>RSS Feed</h2>
    If you just want to follow my blog you can simply keep an eye on it's{" "}
    <a href="/blog/rss.xml">RSS Feed</a>.<h2>Identities on other websites</h2>
    You can also find me on{" "}
    <a href={data.site.siteMetadata.githubUrl}>GitHub</a> and{" "}
    <a href={data.site.siteMetadata.linkedInUrl}>LinkedIn</a>.
  </Layout>
)

export default ContactPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        author
        githubUrl
        linkedInUrl
        email
      }
    }
  }
`
